import commonHelper from '@/app/utility/common.helper.utility';
import investorDetails from './investorDetails';
import tenantLease from '../../leases/tenantLease';
import investorLease from '../../leases/investorLease';
import party from '@/app/components/generalLedger/party';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
export default {
  name: 'masterAgreement',
  watch: {
    currentPage: function() {
      this.getInvestorScheduleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvestorScheduleList();
    }
  },
  components: {
    commonHelper,
    investorDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    tenantLease,
    investorLease,
    party
  },
  data() {
    return {
      payload: null,
      unsubscribe: null,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leaseUnitRowDetails: null,
      investorNo: null,
      tenantNo: null,
      unitName: null,
      subUnitName: null,
      vendorName: null,
      isViewMore: false,
      appStatus: {
        value: null,
        text: null
      },
      leasePrj: {
        value: null,
        text: null
      },
      selectedVendor: {
        name: null,
        id: null
      },
      tenantLease: {
        value: null,
        text: null
      },
      investorLease: {
        value: null,
        text: null
      },
      gridData: [],
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor No.'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'vendor_id'
        },
        {
          key: 'vendor_name'
        },
        {
          key:'vendor_site_id'
        },
        {
          key: 'vendor_site_name',
          label: 'Vendor Site'
        },
        {
          key: 'vendor_site_address'
        },
        {
          key: 'payment_term'
        },
        {
          key: 'lease_unit_name',
          label: 'Lease Office'
        },
        {
          key: 'fms_sub_unit_name',
          label: 'FMS SubUnit'
        },
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },

        {
          key: 'approval_status'
        }
      ],
      selectedLeaseDetails: null,
      scheduleHdrId: null,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      selectedGridData: null,
      showCommonModal: false,
      showPartyModal: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddLeaseUnitModal = true;
          this.leaseUnitRowDetails = null;
        }
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseSchedule/getInvestorScheduleList',
            'investor-schedule',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getInvestorScheduleList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_tenant_agreement_hdr_id: this.tenantLease.value,
        lease_investor_agreement_hdr_id: this.investorLease.value,
        vendor_id: this.selectedVendor.id,
        approval_status: this.appStatus.value,
        lease_unit_name: this.unitName,
        fms_sub_unit_name: this.subUnitName,
        lease_prj_id: this.leasePrj.value
      };
      this.loader = true;
      this.$store
        .dispatch('leaseSchedule/getInvestorScheduleList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedLease(item) {
      this.selectedLeaseDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.scheduleHdrId = item.lease_investor_schedule_hdr_id;
    },
    clearSearchFilters() {
      this.unitName = null;
      this.subUnitName = null;
      this.tenantLease = {
        value: null,
        text: null
      };
      this.investorLease = {
        value: null,
        text: null
      };
      this.selectedVendor = {
        name: null,
        id: null
      };
      this.appStatus = {
        value: null,
        text: null
      };
      this.leasePrj = {
        value: null,
        text: null
      };
      this.gridData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    hideAddLeaseModal() {
      this.showAddLeaseUnitModal = false;
    },

    selectedvalueSet(item) {
      if (this.vsetCode === 'APPROVAL_STATUS') {
        this.appStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.scheduleHdrId = item.lease_investor_schedule_hdr_id;
      this.showAddLeaseUnitModal = true;
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
    },
    updateLeaseUnitList() {
      this.gridData = [];
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    showHideCommonLeaseModal(flag, modalType) {
      this.showCommonModal = flag;
      this.modalType = modalType;
    },
    selectedLeaseTenant(item) {
      this.tenantLease = {
        value: item.lease_tenant_agreement_hdr_id,
        text: item.lease_tenant_agreement_no
      };
      this.showHideCommonLeaseModal(false, null);
    },
    selectedLeaseInvestor(item) {
      this.investorLease = {
        value: item.lease_investor_agreement_hdr_id,
        text: item.lease_investor_agreement_no
      };
      this.showHideCommonLeaseModal(false, null);
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.selectedVendor.name = item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id);
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.selectedVendor.id = response.data.data;
          }
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'APPROVAL_STATUS') {
        this.appStatus = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj = {
          value: null,
          text: null
        };
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
